<template>
  <div class="member-layout">
    <div class="memberP-page-title">회원가입</div>

    <validateForm ref="joinForm1">

      <div class="input-title">아이디<span class="color-1">*</span></div>
      <div class="input-title-desc">@를 포함한 이메일 주소를 입력해주세요.</div>
      <input-field ref="email" pattern="email.check" v-model="email" placeholder="이메일" @focusout="chkEmail" :errorMessage="emailMsg" maxLength="100"/>

      <div class="input-title">비밀번호<span class="color-1">*</span></div>
      <div class="input-title-desc">영문,숫자,특수문자 조합으로 8~20자 이내로 입력해주세요.</div>
      <input-field ref="password" pattern="password.check" v-model="password" placeholder="비밀번호" type="password" :errorMessage="passwordMsg" maxLength="20"/>

      <div class="input-title">비밀번호 확인<span class="color-1">*</span></div>
      <input-field v-model="password2" placeholder="새 비밀번호 확인" type="password" :errorMessage="password2Msg" maxLength="20"/>

      <div class="input-title">닉네임<span class="color-1">*</span></div>
      <div class="input-title-desc">중복되지 않은 닉네임을 입력해주세요. (2~15자)</div>
      <input-field ref="name" pattern="name.check" v-model="name" placeholder="닉네임" maxLength="15" @focusout="chkName" :errorMessage="nameMsg"/>

      <phone-verify ref="phoneVerify" />

      <agree ref="agree" />

      <div class="mt-50" @click="save">
        <button class="btn btn-x-large color-1 btn-block">회원가입</button>
      </div>
    </validateForm>

  </div>
</template>

<script>
import {mapActions} from "vuex"

export default {
  name: "joinEmail",
  data: function () {
    return {
      email: '',
      password: '',
      password2: '',
      name: '',
      emailMsg: '',
      passwordMsg: '',
      password2Msg: '',
      nameMsg: '',
    }
  },
  methods:{
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async chkEmail() {
      this.emailMsg = ''
      this.email = this.email.removeSpace()
      if (!this.email) {
        this.emailMsg = this.$msg('required.input')
        return false
      }
      if (!this.email.patternCheck('email')) {
        this.emailMsg = this.$msg('email.check')
        return false
      }
      const { result } = await this.$api.chkEmail({ email: this.email })
      switch (result) {
        case 'success':
          this.emailMsg = ''
          break;
        case 'fail':
        case 'email-no-input':
        case 'email-type-fail':
          this.emailMsg = this.$msg('email.check')
          break;
        case 'email-duple':
          this.emailMsg = this.$msg('email.exist')
          break;
        case 'email-kakao':
          this.emailMsg = this.$msg('email.exist.kakaosync')
          break;
        case 'email-leave':
          this.emailMsg = this.$msg('email.leave')
          break;
        default:
          this.emailMsg = this.$msg('email.check')
      }
    },
    chkPassword() {
      this.passwordMsg = ''
      this.password2Msg = ''
      if (!this.password) {
        this.passwordMsg = this.$msg('required.input')
        return false
      }
      if (!this.password.patternCheck('password')) {
        this.passwordMsg = this.$msg('password.check')
        return false
      }
      if (!this.password2 || this.password !== this.password2) {
        this.password2Msg = this.$msg('password.no-match')
        return false
      }
    },
    async chkName() {
      this.name = this.name.removeSpace()
      if (!this.name) {
        this.nameMsg = this.$msg('required.input')
        return false
      }
      if (!this.name.patternCheck('name')) {
        this.nameMsg = this.$msg('name.check')
        return false
      }
      const { result } = await this.$api.chkName({ name: this.name })
      switch (result) {
        case 'success':
          this.nameMsg = ''
          break;
        case 'fail':
        case 'name-no-input':
        case 'name-check':
        case 'name-length-fail':
          this.nameMsg = this.$msg('name.check')
          break;
        case 'name-duple':
          this.nameMsg = this.$msg('name.exist')
          break;
        default:
          this.nameMsg = this.$msg('name.check')
      }
    },
    async save() {
      await this.chkEmail()
      this.chkPassword()
      await this.chkName()
      if (this.$refs.joinForm1.validate() && this.$refs.phoneVerify.validate() && this.$refs.agree.chkAgree()) {
        const params = {
          email: this.email,
          password: this.password,
          name: this.name,
          phone: this.$refs.phoneVerify.phone,
          device: 'P',
          isChecked: this.$refs.agree.optional1 ? 1 : 0,
        }
        const { result, data } = await this.$api.joinEmail(params)
        if (result === 'success') {
          if (data.input.length > 0) {
            for (let src of data.input) {
              let script = document.createElement('script')
              script.setAttribute('src', src)
              document.head.appendChild(script)
            }
          }
          setTimeout(() => {
            if (data.script.length > 0) {
              let script = document.createElement('script')
              script.innerHTML = data.script
              document.head.appendChild(script)
            }
          }, 500)

          const vm = this
          setTimeout(() => {
            vm.login(params)
          }, 1000)
        }
        else {
          this.alertPopup(this.$msg(`${result}`))
        }
      }
    },
    async login(params) {
      const { result, data } = await this.$api.loginEmail(params)
      if (result === 'success') {
        const token = data.token
        const info = this.$parseJwt(token)
        if (info) {
          localStorage.setItem('auth', token)
          localStorage.setItem('id', info.id)
          localStorage.setItem('email', info.email)
          localStorage.setItem('name', info.name)
          localStorage.setItem('url', info.url ? info.url : '')
          localStorage.setItem('target', info.target)
          localStorage.setItem('phone', info.phone)
        }
        location.href = "/join/complete"
      }
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
        },
      })
    },
  }
}
</script>
